import { axiosConfig } from '../api/axios-config.js'

// 裝置
export const announcement = {
  // 取得清單
  get: (id, token, additionalParams = {}) => {
    return axiosConfig.get(`/api/announcements/${id || ''}`, { headers: token.headers, params: additionalParams });
  },

  // 創建
  create: (data, token) => axiosConfig.post('/api/announcements', data, token),

  getCategories: (token, additionalParams = {}) => {
    return axiosConfig.get(`/api/announcements/categories`, { headers: token.headers, params: additionalParams });
  },
}
