<template>
  <div class="announcements">
    <div class="container-fluid py-4">
      <h2 class="h4 fw-medium mb-4">{{ $t("__announcements") }}</h2>
      <div class="d-flex flex-wrap flex-md-nowrap align-items-center justify-content-start justify-content-md-between">

        <div class="device-select me-2 mb-2">
          <select v-model="filterType" class="form-select rounded-pill me-2" @change="getAnnouncements">
            <option :value="null">{{ $t("__all") }}</option>
            <option :value="0">
              {{ $t("__announcementStatusDraft") }}
            </option>
            <option :value="1">
              {{ $t("__announcementStatusPublished") }}
            </option>
            <option :value="2">
              {{ $t("__announcementStatusCancel") }}
            </option>
          </select>
        </div>

        <button @click="announcementAdd()" class="mb-2 fs-5 btn btn-primary px-2 rounded-circle lh-sm">
          <i class="bi bi-plus"></i>
        </button>
      </div>
      <div class="card">
        <div class="card-body">
          <b-table
            :responsive="$store.state.windowWidth > $store.state.breakpoints.sm"
            :stacked="$store.state.windowWidth <= $store.state.breakpoints.sm"
            striped
            borderless
            no-provider-filtering
            head-variant="light"
            selectable
            select-mode="multi"
            :items="announcements.data"
            :sort-desc="sortDesc"
            :sort-by="sortBy"
            :fields="fields"
            ref="selectableTable"
          >
            <template v-slot:head()="scope">
              <div class="text-nowrap">
                {{ scope.label }}
              </div>
            </template>
            <template v-slot:cell(selected)="{ rowSelected }">
              <input
                :checked="rowSelected"
                class="form-check-input"
                type="checkbox"
                disabled
              />
            </template>
            <template v-slot:cell(agency)="data">
              {{  data.item.agency_id ? data.item.agency.name + " / " + data.item.agency.codename : '' }}
            </template>
            <template v-slot:cell(agent)="data">
              <div v-if="isSystemManage && data.item.manager_id !== userInfo.id">
              {{ data.item.manager_id ? data.item.manager.name + " / " + data.item.manager.username : '' }}
              </div>
            </template>
            <template v-slot:cell(active)="data">
              <div class="d-flex">
                <button
                  v-if="data.item.status !== 1"
                  @click="$router.push({ name: 'AnnouncementEdit', params: { id: data.item.id } })"
                  type="button"
                  class="btn btn-link px-1 text-dark"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  :title="$t('__edit')"
                >
                  <i class="bi bi-pencil mx-1 fs-5"></i>
                </button>
              </div>
            </template>
          </b-table>
        </div>
      </div>
      <div class="row align-items-center mt-3" v-if="announcements.total">
        <div class="col-sm col-12 text-right text-sm-left">
          {{ $t('__paginationInfo', {
            startNub: announcements.current_page == 1
              ? 1
              : announcements.per_page * (announcements.current_page - 1) + 1,
            entNub: announcements.current_page == 1
              ? announcements.total > announcements.per_page
                ? announcements.per_page
                : announcements.total
              : announcements.per_page * announcements.current_page > announcements.total
                ? announcements.total
                : announcements.per_page * announcements.current_page,
            sum: announcements.total
            })
          }}
        </div>
        <div class="col-sm col-12">
        <select @change="getAnnouncements(1)" class="form-select rounded-pill me-2  mb-2" v-model="announcements.per_page">
            <option :value="10">
              {{ '顯示數量／頁：10' }}
            </option>
            <option :value="25">
              {{ '顯示數量／頁：25' }}
            </option>
            <option :value="50">
              {{ '顯示數量／頁：50' }}
            </option>
          </select>
        </div>
        <div class="col-sm col-12">
          <b-pagination
            v-if="announcements.total > announcements.per_page"
            v-model="announcements.current_page"
            :total-rows="announcements.total"
            :per-page="announcements.per_page"
            align="right"
            first-number
            last-number
            class="mb-sm-0 mb-3 mt-sm-0 mt-3"
            @page-click="changePage"
          ></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Tooltip } from 'bootstrap'
import i18n from '@/lang/lang.js'
import { agency } from '@/http/api/agency.js'
import { mapState, mapMutations } from 'vuex'
import { announcement } from "@/http/api/announcement";

export default {
  name: 'Announcements',
  data () {
    return {
      sortBy: 'serial_id',
      sortDesc: false,
      allAgencys: null,
      filterType: null,
      bootstrap_tooltip: null,
      announcements: {
        data: [],
        total: 0,
        per_page: 10,
        from: 1,
        to: 0,
        current_page: 1
      },
    }
  },
  computed: {
    ...mapState(['token', 'userInfo']),
    fields () {
      return [
        {
          key: 'updatedAt',
          label: i18n.t('__announcementUpdatedAt'),
          class: 'align-middle width-150',
          sortable: true
        },
        {
          key: 'statusName',
          label: i18n.t('__announcementStatus'),
          class: 'align-middle',
          sortable: false
        },
        {
          key: 'category.name',
          label: i18n.t('__announcementCategory'),
          class: 'align-middle',
          sortable: false
        },
        {
          key: 'title',
          label: i18n.t('__announcementTitle'),
          class: 'align-middle',
          sortable: false
        },
        {
          key: 'content',
          label: i18n.t('__announcementContent'),
          class: 'align-middle max-width-250 pre-content',
          sortable: false
        },
        {
          key: 'manager.name',
          label: i18n.t('__announcementManagerName'),
          class: 'align-middle',
          sortable: false
        },
        {
          key: this.isShowSelected ? null : 'active',
          label: i18n.t('__active'),
          tdClass: 'align-middle',
          class: 'align-middle',
          sortable: false
        }
      ]
    },
    permissions () {
      return this.userInfo.permissions
    },
    isSystemManage () {
      let systemManage = false
      if (!this.permissions) return false
      this.permissions.forEach((item) => {
        if (item.name === 'system-manage') {
          systemManage = true
        }
      })
      return systemManage
    }
  },
  watch: {
  },
  methods: {
    ...mapMutations(['Loading', 'Loaded', 'AlertsInfo', 'Alerted']),
    getAnnouncements(page) {
      const vm = this
      vm.Loading()

      if (page === undefined) {
        page = 1
      }

      const params = {
        type: vm.filterType,
        page: page,
        per_page: vm.announcements.per_page // 添加每頁顯示數量作為請求參數
      };

      announcement.get('', vm.token, params).then((res) => {
        if (res.status <= 201 && res.data.status === 'success') {
          vm.announcements = res.data.data
          vm.initBootstrapTooltip()
        } else {
          vm.AlertsInfo({
            state: 'error',
            title: i18n.t('__error'),
            info: res.data.errMsg.toString()
          })
          vm.Alerted()
        }
        vm.Loaded()
      }).catch((err) => {
        vm.AlertsInfo({
          state: 'error',
          title: i18n.t('__error'),
          info: err
        })
        vm.Alerted()
        vm.Loaded()
      })
    },
    changePage: function (bvEvent, page) {
      const vm = this
      vm.getAnnouncements(page);
    },
    announcementAdd() {
      const vm = this
      vm.$router.push({ name: 'AnnouncementAdd' })
    },
    getAllAgency () {
      const vm = this
      agency.get('', this.token).then((res) => {
        if (res.status <= 201 && res.data.status === 'success') {
          vm.allAgencys = res.data.data
        }
      })
    },
    initBootstrapTooltip () {
      this.$nextTick(function () {
        this.bootstrap_tooltip = [].slice
          .call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
          .map(function (tooltipTriggerEl) {
            return new Tooltip(tooltipTriggerEl)
          })
        document.querySelectorAll('[data-bs-toggle="tooltip"]').forEach(ele => {
          ele.addEventListener('shown.bs.tooltip', function () {
            setTimeout(() => {
              Tooltip.getInstance(ele).hide()
            }, 600)
          })
        })
      })
    },
    hideTooltip () {
      if (this.bootstrap_tooltip && this.bootstrap_tooltip.length) {
        this.bootstrap_tooltip.forEach((tooltip) => {
          tooltip.hide()
        })
      }
    },
  },
  created () {
    this.getAnnouncements()
    this.getAllAgency()
  },
  mounted () {
  }
}
</script>

<style  lang="scss">
.max-width-250 {
  max-width: 250px;
}

.width-150 {
  width: 150px !important;
}

.pre-content {
  white-space: break-spaces;
  word-wrap: break-word;
  word-break: break-word;
}
</style>
